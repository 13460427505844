@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gilmer";
  src: url("./assets/fonts/Gilmer-Bold.otf") format("otf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilmer";
  src: url("./assets/fonts/Gilmer-Light.otf") format("otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilmer";
  src: url("./assets/fonts/Gilmer-Thin.otf") format("otf");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Gilmer-Thin.otf") format("otf");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Söhne-Dreiviertelfett.ttf") format("otf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Söhne-Kräftig.ttf") format("otf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Söhne-Buch.ttf") format("otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sohne";
  src: url("./assets/fonts/Gilmer-Light.otf") format("otf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  overflow-x: hidden;
  margin: 0;
  background-color: #101010;
  font-family: "Sohne", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  @apply text-[12px] md:text-[14px] lg:text-[16px];
}

.noScroll {
  overflow: hidden;
}

.bgBlur {
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(3px);
}

.bgBlurLighter {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(3px);
}

.whiteborder {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 30px;
  padding: 1px;
}

.moduleborder {
  background: #2c2c2c;
  height: 100%;
}

.tec-bg {
  background-position: top;
}

@media (max-width: 768px) {
  .tec-bg {
    background: url(./assets/images/webp/tec-bg.webp);
    background-size: cover;
    background-position: center;
  }
}

@media (min-width: 1440px) {
  .tec-bg {
    background: url(./assets/images/webp/tec-bg.webp);
    background-size: cover;
    background-position: center;
  }
}

.text-border {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: #fff;
  letter-spacing: 0.04em;
  transition: all 0.5s;
}

.text-border:hover {
  color: #000000;
}

.features::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 60%;
  left: 5%;
  top: 20%;
  background: #286b9b;
  filter: blur(250px);
}

.bg-shadow::after {
  content: "";
  position: absolute;
  width: 463.44px;
  height: 346.93px;
  left: 474.93px;
  top: 400.96px;
  background: #41a5ee;
  filter: blur(293px);
  transform: rotate(-92.21deg);
}

.bg-shadow1 {
  position: absolute;
  width: 500.62px;
  height: 866.59px;
  left: 70%;
  top: 0;
  background: rgba(65, 165, 238, 0.8);
  filter: blur(385px);
  transform: rotate(-14.55deg);
}

.blueImageWrapper {
  background: radial-gradient(circle, #004a62 0%, #000000 45%);
}

.blueImageWrapper:hover {
  background: radial-gradient(circle, #8fe3ff 0%, #ffffff 45%);
}

.blueImageWrapper:hover .blueImage {
  filter: invert(1);
  background-color: none;
  background: transparent;
}

.swiper-keyFeatures {
  height: calc(100vh - 170px) !important;
  max-height: 1000px !important;
  overflow: hidden;
}

.swiper-customers {
  overflow: hidden;
  height: 420px !important;
  width: calc(100% - 150px) !important;
}

@media screen and (min-width: 1536px) {
  .swiper-customers {
    height: 430px !important;
  }
}

@media screen and (max-width: 1535px) {
  .swiper-customers {
    height: 400px !important;
  }
}

@media screen and (max-width: 1440px) {
  .swiper-customers {
    height: 360px !important;
  }
}

@media screen and (max-width: 375px) {
  .swiper-customers {
    height: 400px !important;
  }
}

@media screen and (max-width: 319px) {
  .swiper-customers {
    height: 440px !important;
  }
}

.swiper-slide {
  height: 100% !important;
  overflow: hidden;
}

.swiper-wrapper {
  height: 100% !important;
}

.sliderImage {
  max-height: calc(100vh - 170px);
}

@media screen and (max-width: 768px) {
  .sliderImage {
    max-height: calc((100vh - 100px) / 2);
    width: auto !important;
  }

  .swiper-keyFeatures {
    height: calc(100vh - 100px) !important;
  }

  .swiper-customers {
    width: calc(100% - 100px) !important;
  }

  .clamp {
    font-size: clamp(3rem, 10vw, 14.69188rem);
  }
}

.essayBackground {
  position: relative;
}

.essayBackground::after {
  position: absolute;
  width: 25%;
  height: 80%;
  flex-shrink: 0;
  content: "";
  top: 0;
  left: 30%;

  background: #41a5ee;
  filter: blur(555px);
  transform: rotate(-14.55deg);
}

@media screen and (max-width: 1024px) {
  .essayBackground::after {
    width: 40%;
    height: 90%;
  }
}

.blackgradient {
  background: linear-gradient(173deg, #000 0%, #2d2d2d 100%);
}

.iframe {
  width: 100%;
  height: 100%;
  margin-inline: 0 !important;
}

.blogsBg {
  position: relative;
  top: -100px;
  z-index: 10;
  background: url(./assets/images/webp/BlogsBack.webp);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .blogsBg {
    background-size: cover;
    background-position: 50% 0;
  }
}

.transitionAppearing {
  transition: all 0.6s ease-out;
}

@media screen and (max-width: 1024px) {
  .transitionAppearing {
    transition: all 0.5s ease-out;
  }
}

@media screen and (max-width: 768px) {
  .transitionAppearing {
    transition: all 0.3s ease-out;
  }
}

.scroll-container::-webkit-scrollbar {
  width: 5px;
}

.scroll-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(70, 70, 70);
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: rgb(117, 117, 117);
  border-radius: 10px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  color: white !important;
  background-color: #232323;
  transition: 0.3s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: white;
  color: black !important;
}

@media screen and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }
}

.linkContainer:hover .hoverableLink {
  color: #787878;
}

.gradientText {
  background: -webkit-linear-gradient(
    right,
    transparent,
    #3333333a,
    transparent
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quotes::after {
  content: "";
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background: #ebe3d8 url("./assets/icons/Quotes.svg") center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.header2 {
  font-size: 28px;

  @media (min-width: 768px) {
    font-size: 40px;
  }

  @media (min-width: 1024px) {
    font-size: 48px;
  }
}

.featuresBg {
  position: relative;
  background: url('./assets/images/AndyPhoneBg.png')  top/cover no-repeat;
}

@media (max-width: 768px) {
  .featuresBg {
      background: url('./assets/images/AndyPhoneBgCropped.png') 0px 0px/cover no-repeat;
  }
}

@media (min-width: 1260px) {
  .coinBg {
    background: url('./assets/images/coinBg.jpg') center/cover no-repeat;
    background-size: 150%;
  }
}

.andyShadow {
  background: linear-gradient(180deg, rgba(154, 127, 20, 0.7) 0%, rgba(154, 127, 20) 100%);
}

.clamp {
  font-size: clamp(0.2rem, 13.6vw, 13.19625rem);
}

.usersStroke {
  -webkit-text-fill-color: #0f0f0f;
  text-shadow: -1px -1px 0 rgba(255,255,255,30%), 1px -1px 0 rgba(255,255,255,30%), -1px 1px 0 rgba(255,255,255,30%), 1px 1px 0 rgba(255,255,255,30%);
}